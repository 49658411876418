import React from 'react';
// import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row } from 'react-grid-system';
// import Title from 'components/title';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  margin-top: -4rem;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const SectionWrapper = styled.section`
  .section__img-inner {
    background-image: url(https://s3.amazonaws.com/projects.tradeincool.com/tic/version8/images/Projects/pepsi/pepsi2.jpg);
    background-position: 50% 0%;
  }
  ${MEDIA.TABLET`
    display: block;
    .section__img-inner {
      background-position: 0% 0%;
    }
  `};
`;

const Section = () => (
  <SectionWrapper className="section section--current">
    <div className="section__content">
      <h1 className="section__title">Pepsi</h1>
      <p className="section__description">
        <span className="section__description-inner">
          When Pepsi was ready to launch its newest brand, Pepsi Max, they
          needed an engaging identity that would become part of the consumers
          day-to-day lives. Trade in Cool, working in close partnership with the
          Youniverse.com social networking site, created the perfect solution.
          TIC built a downloadable desktop widget to keep users connected to the
          social network, deliver global soccer news and keep users connected to
          the Pepsi brand.
        </span>
      </p>
    </div>
    <div className="section__img">
      <div className="section__img-inner" />
    </div>
    <div className="section__more">
      <div className="section__more-inner section__more-inner--bg1">
        <span className="section__more-text">Want to know more?</span>
        <a href="/work" className="section__more-link">
          <span className="section__more-linktext">Explore all projects</span>
        </a>
      </div>
    </div>
    <ul className="section__facts">
      <li
        className="section__facts-item section__facts-item--clickable"
        data-gallery="gallery1"
      >
        <div className="section__facts-img">
          <img
            src="https://s3.amazonaws.com/projects.tradeincool.com/tic/version8/images/Projects/pepsi/pepsi2.jpg"
            alt="Some image1"
          />
        </div>
        <h2 className="section__facts-title">Pepsi / Youniverse</h2>
      </li>
      <li className="section__facts-item">
        <h3 className="section__facts-title">Integrated API</h3>
        <span className="section__facts-detail">PHP, REST, Flash</span>
      </li>
    </ul>
  </SectionWrapper>
);

const Hiking = () => (
  <Layout>
    <Container fluid>
      <Row>
        <Wrapper className="sections">
          {/* <Fact /> */}
          <Section />
        </Wrapper>
      </Row>
    </Container>
  </Layout>
);

export default Hiking;
